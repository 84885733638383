import { ErrorMessage, Field, Form, Formik } from "formik";
import { useContext, useEffect, useState } from "react";
import { FormGroup, Row } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import LoadingOverlay from "react-loading-overlay";
import * as yup from "yup";
import { fetchNotifyPasswordChange, fetchValidateLink } from "../../helpers/fetch";
import log from "../../images/logo_iproveedor.png";
import { TexTranslate } from "../../translations/FlattenMessages";
import { Context } from "../context/Context";
import { ShowMessage } from "../showMessage/ShowMessage";
import "./changePassword.css";
import bcryptjs from "bcryptjs";

export const ChangePassword = () => {

    const intl = useIntl();
    const { user, app, setUser } = useContext(Context);

    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");

    const [textNotification, setTextNotification] = useState(TexTranslate(intl, "changePass.This link has expired. Please contact technical support", "changePass.This link has expired. Please contact technical support"));
    const [classNotification, setClassNotification] = useState("alert alert-danger none");

    const [dataLink, setDataLink] = useState();

    const [showLoader, setShowLoader] = useState(false);

    const formSchema = yup.object({
        newPass: yup.string().required(<FormattedMessage id={`changePass.New Password required`} defaultMessage={"changePass.New Password required"} />),
        confirmPass: yup.string().required(<FormattedMessage id={`changePass.Confirm Password required`} defaultMessage={"changePass.Confirm Password required"} />)
    });

    const [showComponent, setShowComponent] = useState(false);

    let search = window.location.search;
    const split = search.split("&language=");
    search = split[0];

    const validateLink = async (search) => {
        let paramsSplit = search.split("code=");
        if (paramsSplit.length > 0) {
            let paramsUrl = paramsSplit[1];

            const resp = await fetchValidateLink(app, paramsUrl);

            if (resp.success && resp.data.validateRefreshLink != null && resp.data.validateRefreshLink !== "used") {
                setShowComponent(true);
                setDataLink(resp.data);
            } else if (resp.success && resp.data.validateRefreshLink === "used") {
                setTextNotification(TexTranslate(intl, "changePass.The password has been successfully changed and an email notification has been sent", "changePass.The password has been successfully changed and an email notification has been sent"));
                setClassNotification("alert alert-primary none");
                setShowComponent(false);
            }
            else {
                setShowComponent(false);
            }
        }
    }

    const handleChangePassword = async () => {

        let paramsUrl;
        let searchUrl = window.location.search ? window.location.search : "invalid";

        if(searchUrl.indexOf("?") === 0){
            searchUrl = searchUrl.substring(1);
        }

        let paramsSplit = searchUrl.split("&");
        for(var i = 0 ; i < paramsSplit.length; i++){
            if(paramsSplit[i].indexOf("code=") >= 0){
                paramsUrl = paramsSplit[i].substring(paramsSplit[i].indexOf("code=")+5);
                break;
            }
        }

        const pattern = /^(?=.*[0-9])(?=.*[a-z\u00f1\u00d1])(?=.*[A-Z\u00f1\u00d1])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])([0-9a-zA-Z\u00f1\u00d1!:;"´.°|¬,´'=+¿¡¨¨[\]()/\-?_\\@#{}\$%\^\&*]{8,})$/;

        if (password !== "" && passwordConfirm !== "") {

            if (password !== passwordConfirm) {

                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "changePass.Passwords must be the same", "changePass.Passwords must be the same"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        confirmButtonText: TexTranslate(intl, "general.Accept"),
                        callbackConfirm: () => {
                            setPassword("");
                            setPasswordConfirm("");
                        },
                        intl
                    }
                )
            } else if (password.match(pattern) === null || password.match(pattern) === undefined) {

                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character.", "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character."),
                        typeMessage: "warning",
                        showCancelButton: false,
                        confirmButtonText: TexTranslate(intl, "general.Accept"),
                        callbackConfirm: () => {
                            setPassword("");
                            setPasswordConfirm("");
                        },
                        intl
                    }
                )

            } else {
                setShowLoader(true);
                
                const passwordBcrypt = await bcryptjs.hash (passwordConfirm, app.salt);

                let dataParams = {
                    ...dataLink,
                    "idAcceso" : passwordBcrypt,
                    "paramsUrl" : paramsUrl
                }
                
                const resp = await fetchNotifyPasswordChange(dataParams, app, user);

                if (resp && resp.success) {

                    setTextNotification(TexTranslate(intl, "changePass.The password has been successfully changed and an email notification has been sent", "changePass.The password has been successfully changed and an email notification has been sent"));
                    setClassNotification("alert alert-primary none");
                    setPassword("");
                    setPasswordConfirm("");
                    setShowComponent(false);
                    
                } else if (resp.msg !== undefined && resp.msg === "currentPassRepeat") {
                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: TexTranslate(intl, "changePass.The password entered has been used before, please enter a new one.", "changePass.The password entered has been used before, please enter a new one."),
                            typeMessage: "warning",
                            showCancelButton: false,
                            confirmButtonText: TexTranslate(intl, "general.Accept"),
                            callbackConfirm: () => {
                                setPassword("");
                                setPasswordConfirm("");
                            },
                            intl
                        }
                    );
                    
    
                } else if (resp.data !== undefined) {

                    if(resp.data.validateRefreshLink != null && resp.data.validateRefreshLink !== "used") {
                        setShowComponent(true);
                        setDataLink(resp.data);
                    } else if (resp.data.validateRefreshLink === "used") {
                        setTextNotification(TexTranslate(intl, "changePass.The password has been successfully changed and an email notification has been sent", "changePass.The password has been successfully changed and an email notification has been sent"));
                        setClassNotification("alert alert-primary none");
                        setShowComponent(false);
                    } else {
                        setShowComponent(false);
                    }

                }else {

                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: TexTranslate(intl, "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character.", "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character."),
                            typeMessage: "warning",
                            showCancelButton: false,
                            confirmButtonText: TexTranslate(intl, "general.Accept"),
                            callbackConfirm: () => {
                                setPassword("");
                                setPasswordConfirm("");
                            },
                            intl
                        }
                    )
                }
                setShowLoader(false);
            }
        }

    }

    setTimeout(() => {
        let formChange = document.querySelector("#formChange");
        let divNotification = document.querySelector("#divNotification");

        if (showComponent) {
            if (formChange) {
                formChange.classList.remove("none");
            }
            if (divNotification) {
                divNotification.classList.add("none");
            }
        } else {

            if (divNotification) {
                divNotification.classList.remove("none");
            }
            if (formChange) {
                formChange.classList.add("none");
            }

        }
    }, 1000);

    useEffect(() => {

        validateLink(search);

        if (split && split.length >= 2 && user.language !== split[1]) {
            setUser({ language: split[1] })
        }

    }, []);

    return (

        showComponent ?


            <LoadingOverlay active={showLoader} spinner
                text={TexTranslate(intl, "general.loading...", "general.loading...")}>



                <div className="section-changePass">
                    <div className="container-changePass">
                        <div className="form">
                            <h2><img src={log} width="250" alt="logo iProveedor" /></h2>

                            <Formik
                                initialValues={{ newPass: "", confirmPass: "" }}
                                validationSchema={formSchema}
                            >
                                {({ isSubmitting, setFieldValue }) => (

                                    <Form>
                                        <FormGroup className="inputBox">
                                            <Field className="input" id="newPass" name="newPass" type="password" placeholder={TexTranslate(intl, "changePass.New Password", "changePass.New Password")}
                                                value={password} onChange={(e) => setPassword(e.target.value)} />
                                            {password === "" ?
                                                <ErrorMessage
                                                    name={"newPass"}
                                                    component="div"
                                                    className="field-error text-danger"
                                                /> : <div> </div>}
                                        </FormGroup>
                                        <FormGroup className="inputBox">
                                            <Field className="input" id="confirmPass" name="confirmPass" type="password" placeholder={TexTranslate(intl, "changePass.Confirm Password", "changePass.Confirm Password")}
                                                value={passwordConfirm} onChange={(e) => setPasswordConfirm(e.target.value)} />
                                            {passwordConfirm === "" ?
                                                <ErrorMessage
                                                    name={"confirmPass"}
                                                    component="div"
                                                    className="field-error text-danger"
                                                /> : <div> </div>}
                                        </FormGroup>
                                        <Row className="d-flex justify-content-center inputBox">
                                            <div className="col-auto">
                                                <button className=" submit-change input" type="submit" onClick={() => handleChangePassword()}>
                                                    <FormattedMessage id={`changePass.Change Password`} defaultMessage={"changePass.Change Password"} />
                                                </button>
                                            </div>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>

            :
            <div id="divNotification" className={classNotification}>
                <h1> {textNotification} </h1>
            </div>
    );
}