import { ErrorMessage, Field, Form, Formik } from 'formik';
import React, { useRef, useContext, useState } from 'react';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import log from "../../images/logo_iproveedor.png";
import "./loginScreem.css";
import { Context } from '../context/Context';
import Locales from "../../translations/Locales";
import * as yup from "yup";
import { useHistory } from 'react-router';
import { fetchIncorrectRecoverPass, fetchSendEmailPassword, fetchUpdateRecoverPassAttempts } from '../../helpers/fetch';
import { ShowMessage } from '../showMessage/ShowMessage';
import { TexTranslate } from '../../translations/FlattenMessages';
import { Timer } from '../ui/Timer';
import LoadingOverlay from 'react-loading-overlay';
import Tooltip from '../ui/tooltip/tooltip';
import interrogacion from '../../images/interrogacion.png'

export const RecoverPassword = () => {
    const history = useHistory();
    const { user, setUser, app } = useContext(Context);
    const [valueCap, setValueCap] = useState(user.language);
    const [valueSelect, setValueSelect] = useState(user.language);
    const locale = Locales(valueSelect);

    const intl = useIntl();

    const [showTimer, setShowTimer] = useState(false);
    const [loadingTime, setLoadingTime] = useState(60);
    const [showLoader, setShowLoader] = useState(false);
    const [showQuestion, setQuestion] = useState(true);

    const formSchema = yup.object({
        nit: yup.string().required(<FormattedMessage id={`recoverPass.Fiscal Identifier required`} defaultMessage={"recoverPass.Fiscal Identifier required"} />)
            .matches(/^[0-9A-Za-zÑñ-]+$/, TexTranslate(intl, "recoverPass.Only letters and numbers are allowed", "recoverPass.Only letters and numbers are allowed")),
        username: yup.string().required(<FormattedMessage id={`recoverPass.user required`} defaultMessage={"recoverPass.user required"} />)
            .matches(/^[0-9A-Za-zÑñ\-_.&*]+$/, TexTranslate(intl, "recoverPass.Invalid user format", "recoverPass.Invalid user format")),
        email: yup.string().required(<FormattedMessage id={`recoverPass.Email required`} defaultMessage={"recoverPass.Email required"} />)
            .matches(/^[a-zA-Z0-9.-_]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/, TexTranslate(intl, "recoverPass.Format email not valid", "recoverPass.Format email not valid")),
    });

    const handleClose = () => {
        history.push('/'+ app.baseName +'index.html', {});
    }

    return (
        <Context.Provider value={{ showTimer, setShowTimer }} >
            <LoadingOverlay active={showLoader} spinner
                text={TexTranslate(intl, "general.loading...", "general.loading...")}>
                <div className="section-login">
                    <div className="container">
                        <div className="form">

                            <h2><img src={log} width="250" alt="logo iProveedor" /></h2>
                            {showTimer ? <div className="divTimer"> <Timer loadingTime={loadingTime} /> </div>
                                : < >
                                    <Formik
                                        initialValues={{ username: "", nit: "", email: "", captcha: "" }}
                                        validationSchema={formSchema}
                                        validate={(values) => {   // Si hay un nit muy largo no se muestra tooltip Interrogacion    
                                            if (values.nit.length > 20) {
                                                setQuestion(false)
                                            } else {
                                                setQuestion(true)
                                            }                                 
                                          }}
                                        onSubmit={async (data, { setSubmitting }) => {
                                            setSubmitting(true);
                                            setShowLoader(true);
                                            const respEmail = await fetchSendEmailPassword(data, app, user);
                                            if (respEmail && respEmail.success) {

                                                const respUpdateRecover = await fetchUpdateRecoverPassAttempts(data, app);

                                                if (respUpdateRecover && respUpdateRecover.success) {
                                                    ShowMessage(
                                                        {
                                                            title: TexTranslate(intl, "general.Successful!", "general.Successful!"),
                                                            message: TexTranslate(intl, "recoverPass.A link was sent to your email to confirm the password change.", "recoverPass.A link was sent to your email to confirm the password change."),
                                                            typeMessage: "success",
                                                            showCancelButton: false,
                                                            intl
                                                        }
                                                    )
                                                } else {
                                                    ShowMessage(
                                                        {
                                                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                                            message: TexTranslate(intl, "recoverPass.The data is not correct. Please check again", "recoverPass.The data is not correct. Please check again"),
                                                            typeMessage: "warning",
                                                            showCancelButton: false,
                                                            intl
                                                        }
                                                    )
                                                }

                                            } else if (respEmail && respEmail.error) {
                                                ShowMessage(
                                                    {
                                                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                                        message: TexTranslate(intl, "recoverPass.You_do_not_have_permission", "recoverPass.You_do_not_have_permission"),
                                                        typeMessage: "warning",
                                                        showCancelButton: false,
                                                        intl
                                                    }
                                                )
                                            } else {
                                                const respValidateRecover = await fetchIncorrectRecoverPass(data, app);
                                                if (respValidateRecover && respValidateRecover.success && respValidateRecover.intentos < 3) {
                                                    ShowMessage(
                                                        {
                                                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                                            message: TexTranslate(intl, "recoverPass.The data is not correct. Please check again", "recoverPass.The data is not correct. Please check again"),
                                                            typeMessage: "warning",
                                                            showCancelButton: false,
                                                            intl
                                                        }
                                                    )
                                                } else {
                                                    setLoadingTime(60);
                                                    setShowTimer(true);
                                                }
                                            }
                                            setSubmitting(false);
                                            setShowLoader(false);
                                        }}
                                    >
                                        {({ isSubmitting, setFieldValue }) => (
                                            <Form>
                                                <h3 className="text-center p-3">
                                                    <span className="fs-5 fw-bold"> <FormattedMessage id={"recoverPass.Recover Password"} defaultMessage={""} /> </span>
                                                </h3>
                                                <FormGroup className="inputBox ">
                                                    <Field className="input" id="recoverNit" name="nit" type="text" placeholder={TexTranslate(intl, "recoverPass.Fiscal Identifier", "recoverPass.Fiscal Identifier")} />
                                                    {showQuestion ? (
                                                        <Tooltip text={TexTranslate(intl, "login.nit_indication", "login.nit_indication")} image={interrogacion} />
                                                    ) : (
                                                        ''
                                                    )}                    
                                                    <ErrorMessage
                                                        name={"nit"}
                                                        component="div"
                                                        className="field-error text-danger"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="inputBox ">
                                                    <Field className="input" id="recoverUsername" name="username" type="text" placeholder={TexTranslate(intl, "recoverPass.user", "recoverPass.user")} />
                                                    <ErrorMessage
                                                        name={"username"}
                                                        component="div"
                                                        className="field-error text-danger"
                                                    />
                                                </FormGroup>
                                                <FormGroup className="inputBox ">
                                                    <Field className="input" id="recoverEmail" name="email" type="text" placeholder={TexTranslate(intl, "recoverPass.Email", "recoverPass.Email")} />
                                                    <ErrorMessage
                                                        name={"email"}
                                                        component="div"
                                                        className="field-error text-danger"
                                                    />
                                                </FormGroup>
                                                <Row >
                                                    <Col lg={5} xs={5} className='inputBox'>
                                                        <button type="button" className="btn btn-outline-danger close-recover"  onClick={() => handleClose()} >
                                                            <FormattedMessage id={`recoverPass.Close`} defaultMessage={"recoverPass.Close"} />
                                                        </button>
                                                    </Col>
                                                    <Col lg={5} xs={5} className='inputBox'>
                                                        <button className=" submit-login input" type="submit" >
                                                            <FormattedMessage id={`recoverPass.Recover`} defaultMessage={"recoverPass.Recover"} />
                                                        </button>
                                                    </Col>
                                                </Row>
                                            </Form>

                                        )}
                                    </Formik>

                                </>
                            }
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </Context.Provider>

    );

};
