import React, { useContext, useState, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import { calcMD5 } from "../../helpers/md5";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { Col, FormGroup, Row } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { ShowMessage } from '../showMessage/ShowMessage';
import { useSelector } from 'react-redux';
import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import bcryptjs from "bcryptjs";
import { TexTranslate } from '../../translations/FlattenMessages';
import log from "../../images/logo_iproveedor.png";
import './sass/changePasswordMandatory.css'
import { fetchNotifyPasswordChangeLogged } from '../../helpers/fetch';
import { Context } from '../context/Context';

export const ChangePasswordMandatory = () => {
    const intl = useIntl();
    const history = useHistory();
    const [showLoader, setShowLoader] = useState(false);
    const { user, app } = useContext(Context);
    const { token } = useSelector(state => state.auth);
    const [valueCap, setValueCap] = useState(user.language);

    const formSchema = yup.object({
        username: yup.string().required(<FormattedMessage id={`login.user required`} defaultMessage={"login.user required"} />),
        currentPass: yup.string().required(<FormattedMessage id={`changePass.current password required`} defaultMessage={"changePass.current password required"} />),
        newPass: yup.string().required(<FormattedMessage id={`changePass.New Password required`} defaultMessage={"changePass.New Password required"} />),
        confirmPass: yup.string().required(<FormattedMessage id={`changePass.Confirm Password required`} defaultMessage={"changePass.Confirm Password required"} />)
            .oneOf([yup.ref('newPass')], <FormattedMessage id={`changePass.Passwords must be the same`} defaultMessage={"changePass.Passwords must be the same"} />),

    });

    const parseJWT = (token) => {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');

        return JSON.parse(window.atob(base64));
    }

    const loginToken = token ? parseJWT(token) : {};

    const usernameDisable = loginToken.user_name ? true : false;
    const [showComponent, setShowComponent] = useState(true);
    const [titleTextNotification, setTitleTextNotification] = useState(TexTranslate(intl, "changePass.Change Password", "changePass.Change Password"));
    const [textNotification, setTextNotification] = useState(TexTranslate(intl, "changePass.Your password has expired, please change your password as follows: For the correct entry of the new password, it must contain: Minimum 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter. - 1 Special character. Then, fill in the following fields:", "changePass.Your password has expired, please change your password as follows: For the correct entry of the new password, it must contain: Minimum 8 characters, 1 number, 1 uppercase letter, 1 lowercase letter. - 1 Special character. Then, fill in the following fields:"));
    const [classNotification, setClassNotification] = useState("alert alert-danger");

    const handleClose = () => {
        history.push('/'+ app.baseName +'/index.html', {});
    }

    const handleChangePasswordMandatory = async (values, { setFieldValue, setSubmitting }) => {
        setSubmitting(true);
        try {
            const pattern = /^(?=.*[0-9])(?=.*[a-z\u00f1\u00d1])(?=.*[A-Z\u00f1\u00d1])(?=.*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~])([0-9a-zA-Z\u00f1\u00d1!:;"´.°|¬,´'=+¿¡¨¨[\]()/\-?_\\@#{}\$%\^\&*]{8,})$/;
            let username = values.username;
            let currentPassword = values.currentPass;
            let newPassword = values.newPass;
            let passwordConfirm = values.confirmPass;

            if (username !== "" && currentPassword !== "" && newPassword !== "" && passwordConfirm !== "") {

                if (newPassword !== passwordConfirm) {

                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: TexTranslate(intl, "changePass.Passwords must be the same", "changePass.Passwords must be the same"),
                            typeMessage: "warning",
                            showCancelButton: false,
                            confirmButtonText: TexTranslate(intl, "general.Accept"),
                            callbackConfirm: () => {
                                setFieldValue('currentPass', "");
                                setFieldValue('newPass', "");
                                setFieldValue('confirmPass', "");
                            },
                            intl
                        }
                    );

                } else if (newPassword.match(pattern) === null || newPassword.match(pattern) === undefined) {

                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: TexTranslate(intl, "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character.", "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter. - 1 Special character."),
                            typeMessage: "warning",
                            showCancelButton: false,
                            confirmButtonText: TexTranslate(intl, "general.Accept"),
                            callbackConfirm: () => {
                                setFieldValue('currentPass', "");
                                setFieldValue('newPass', "");
                                setFieldValue('confirmPass', "");
                            },
                            intl
                        }
                    );

                } else {
                    
                    setShowLoader(true);
                    let dataChangePass = {};

                    dataChangePass.passMd5 = calcMD5(currentPassword);
                    dataChangePass.newPass = await bcryptjs.hash (newPassword, app.salt);
                    dataChangePass.currentPass = await bcryptjs.hash ( currentPassword, app.salt);
                    dataChangePass.usrEncrypt = await bcryptjs.hash ( username, app.salt);
                    debugger;
                    const resp = await fetchNotifyPasswordChangeLogged(dataChangePass, token, app, user);

                    if (resp && resp.success) {

                        setTextNotification(TexTranslate(intl, "changePass.Your password was updated successfully, a notification has been sent to your email", "changePass.Your password was updated successfully, a notification has been sent to your email"));
                        setTitleTextNotification(TexTranslate(intl, "changePass.Successful password change", "changePass.Successful password change"))
                        setClassNotification("alert alert-primary");
                        setFieldValue('currentPass', "");
                        setFieldValue('newPass', "");
                        setFieldValue('confirmPass', "");
                        setShowComponent(false);
                    } else if (resp && resp.msg) {
                        if (resp.msg === "currentPassError") {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "changePass.The current password does not correspond message", "changePass.The current password does not correspond message"),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                                    callbackConfirm: () => {
                                        setFieldValue('currentPass', "");
                                        setFieldValue('newPass', "");
                                        setFieldValue('confirmPass', "");
                                    },
                                    intl
                                }
                            );
                        } else if (resp.msg === "equals") {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "changePass.The current password you entered cannot be the same as the new password", "changePass.The current password you entered cannot be the same as the new password"),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                                    callbackConfirm: () => {
                                        setFieldValue('currentPass', "");
                                        setFieldValue('newPass', "");
                                        setFieldValue('confirmPass', "");
                                    },
                                    intl
                                }
                            );
                        }
                        else if (resp.msg === "oldPass") {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "changePass.This password was previously entered, please enter a different password", "changePass.This password was previously entered, please enter a different password"),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                                    callbackConfirm: () => {
                                        setFieldValue('currentPass', "");
                                        setFieldValue('newPass', "");
                                        setFieldValue('confirmPass', "");
                                    },
                                    intl
                                }
                            );
                        } else if (resp.msg === "currentPassRepeat") {
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "changePass.The password entered has been used before, please enter a new one.", "changePass.The password entered has been used before, please enter a new one."),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                                    callbackConfirm: () => {
                                        setFieldValue('currentPass', "");
                                        setFieldValue('newPass', "");
                                        setFieldValue('confirmPass', "");
                                    },
                                    intl
                                }
                            );
                            
            
                        } else {
                            console.error(resp.msg);
                            ShowMessage(
                                {
                                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                    message: TexTranslate(intl, "changePass.An error occurred while trying to change the password, please try again", "changePass.An error occurred while trying to change the password, please try again"),
                                    typeMessage: "warning",
                                    showCancelButton: false,
                                    confirmButtonText: TexTranslate(intl, "general.Accept"),
                                    callbackConfirm: () => {
                                        setFieldValue('currentPass', "");
                                        setFieldValue('newPass', "");
                                        setFieldValue('confirmPass', "");
                                    },
                                    intl
                                }
                            );
                        }

                    } else {

                        ShowMessage(
                            {
                                title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                message: TexTranslate(intl, "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter.", "changePass.For the correct entry of the new password, it must contain: At least 8 characters. - 1 Number. - One capital letter. - 1 Lowercase Letter."),
                                typeMessage: "warning",
                                showCancelButton: false,
                                confirmButtonText: TexTranslate(intl, "general.Accept"),
                                callbackConfirm: () => {
                                    setFieldValue('currentPass', "");
                                    setFieldValue('newPass', "");
                                    setFieldValue('confirmPass', "");
                                },
                                intl
                            }
                        );
                    }
                    setShowLoader(false);
                }
            }
        } catch (e) {
            console.error(e);
            setShowLoader(false);
        }
        setSubmitting(false);

    }

    const renderTooltip = (props) => (
        <Tooltip id="field-tooltip" {...props} >
            <FormattedMessage id={`changePass.Character limit: 50`} defaultMessage={"changePass.Character limit: 50"} />
        </Tooltip>
    );

    return (
        <LoadingOverlay active={showLoader} spinner
            text={TexTranslate(intl, "general.loading...", "general.loading...")} >
            <div className="section-changepass">
                <div className="contendor">
                    <div className="form">
                        <h2><img src={log} width="250" alt="logo iProveedor" /></h2>
                        <div id="divText" className={classNotification + ` text-justify`}>
                            <h5 className='text-center'>
                                <FormattedMessage id={titleTextNotification} defaultMessage={titleTextNotification} />
                            </h5>
                            <FormattedMessage id={textNotification} defaultMessage={textNotification} />
                        </div>
                        {showComponent &&
                            <Formik
                                initialValues={{
                                    username: loginToken.user_name ? loginToken.user_name : ""
                                    , currentPass: "", newPass: "", confirmPass: "", captcha: ""
                                }}
                                validationSchema={formSchema}
                                onSubmit={handleChangePasswordMandatory}
                            >
                                {({ isSubmitting, setFieldValue }) => (
                                    <Form>
                                        <FormGroup className="inputBox">
                                            <Field className="input" id="username" name="username" type="text"
                                                placeholder={TexTranslate(intl, "login.user", "login.user")} disabled={usernameDisable} />
                                            <ErrorMessage
                                                name={"username"}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </FormGroup>
                                        <FormGroup className="inputBox">
                                            <Field className="input" id="currentPass" name="currentPass" type="password"
                                                placeholder={TexTranslate(intl, "changePass.currentPass", "changePass.currentPass")} />
                                            <ErrorMessage
                                                name="currentPass"
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </FormGroup>
                                        <FormGroup className="inputBox">

                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                            >
                                                <Field className="input" id="newPass" name="newPass" type="password" placeholder={TexTranslate(intl, "changePass.New Password", "changePass.New Password")} maxLength={50} size={50}
                                                />
                                            </OverlayTrigger>
                                            <ErrorMessage
                                                name={"newPass"}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </FormGroup>
                                        <FormGroup className="inputBox">
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 250, hide: 400 }}
                                                overlay={renderTooltip}
                                            >
                                                <Field className="input" id="confirmPass" name="confirmPass" type="password" placeholder={TexTranslate(intl, "changePass.Confirm Password", "changePass.Confirm Password")} maxLength={50} size={50}
                                                />
                                            </OverlayTrigger>
                                            <ErrorMessage
                                                name={"confirmPass"}
                                                component="div"
                                                className="field-error text-danger"
                                            />
                                        </FormGroup>
                                        <Row>
                                            <Col lg={3} xs={4}>
                                                <button type="button" className="btn btn-outline-danger close-change-pass-mand" onClick={() => handleClose()} >
                                                    <FormattedMessage id={`recoverPass.Close`} defaultMessage={"recoverPass.Close"} />
                                                </button>
                                            </Col>
                                            <Col lg={9} xs={8} >
                                                <button disabled={isSubmitting} className="submit-change-pass-mand" type="submit" >
                                                    <FormattedMessage id={`changePass.Change Password`} defaultMessage={"changePass.Change Password"} />
                                                </button>
                                            </Col>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        }
                        {!showComponent &&
                            <Row className="mb-3 d-flex justify-content-center">
                                <Col lg={8} xs={8} className="d-flex justify-content-center">
                                    <button className="submit-change-pass-mand" onClick={() => handleClose()}>
                                        <FormattedMessage id={`changePass.Log in`} defaultMessage={"changePass.Log in"} />
                                    </button>
                                </Col>
                            </Row>
                        }
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );

}