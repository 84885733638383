import React, { useEffect, useContext } from 'react';
import { useIntl } from 'react-intl';
import LoadingOverlay from 'react-loading-overlay';
import { TexTranslate } from '../../translations/FlattenMessages';
import { Context } from '../context/Context';


export const DashboardScreem = () => {
    
    const {app} = useContext(Context);
    const intl = useIntl();

    useEffect(() => {
        const idioma = sessionStorage.getItem("idioma") || 'es';
        
        if(sessionStorage.getItem("token.type") === "admin" && app.frontAdminDashboard !== undefined){
            const urlEncode = encodeURI(`${app.frontAdminDashboard}/index.html?userRedirect=${true}&idioma=${idioma}`);
            window.location.href = urlEncode;
            return;
        }
        
        const urlEncode = encodeURI(`${app.frontDashboard}/index.html?userRedirect=${true}&idioma=${idioma}`);

        window.location.href = urlEncode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <React.Fragment>
             <LoadingOverlay active={true} spinner
                text={TexTranslate(intl, "general.loading...", "general.loading...")} />
        </React.Fragment>
    )
}
