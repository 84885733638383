import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom'

export const PrivateRoute = ({
     isAuthenticated,
     component: Component,
     redirect,
     ...rest
}) => {


    return (
        <Route { ...rest }
            component={ (props) => (
                
                ( isAuthenticated )
                ? ( <Component { ...props } />)
                : ( <Redirect to={redirect ? redirect : "/login/index.html"} />)
            )}

        />
    )
}

PrivateRoute.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}