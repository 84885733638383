import { useContext } from "react";
import { Context } from "../componets/context/Context";

export const useTemporayKey = () => {

    const { app } = useContext(Context);

    const validateTemporal = async (inputValue, dataUser, showMessage, captcha) => {

        let val = inputValue && inputValue.value ? inputValue.value : inputValue;
        const url = `${app.url}/oauth/token`;
        const credenciales = btoa('Vd2t!qCdi$q1'.concat(':').concat('ryNcQ1u#Y0Vo'));

        let params = new URLSearchParams();

        params.set('grant_type', 'password');
        params.set('username', dataUser.username);
        params.set('password', dataUser.password);
        params.set('passwordBcrypt', dataUser.passwordBcrypt);
        params.set('code', val);
        params.set('language', dataUser.language || 'es');
        params.set('captcha', captcha);

        return fetch(url,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': 'Basic '.concat(credenciales)
            },
            body:params,
        }
        )

    }

    return {
        validateTemporal,
    }

}
