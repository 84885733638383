export const getAppURL = () => {
    const baseName = process.env.REACT_APP_BASENAME ? process.env.REACT_APP_BASENAME : process.env.PUBLIC_URL;
    const basePathFiles = process.env.NODE_ENV !== 'development' ? `/${baseName}/` : "";
    const baseNameDashboard = process.env.REACT_APP_BASENAME_DASHBOARD ? process.env.REACT_APP_BASENAME_DASHBOARD : "dashboard";
    const url = process.env.REACT_APP_URL;
    const apiCore = process.env.REACT_APP_APICORE;
    const loginFront = process.env.REACT_APP_FRONTLOGIN;
    const frontDashboard = process.env.REACT_APP_FRONTDASHBOARD;
    const frontAdminDashboard = process.env.REACT_APP_FRONTADMINDASHBOARD;

    const result = {
        baseName,
        baseNameDashboard,
        basePathFiles,
        url,
        apiCore,
        loginFront,
        frontDashboard,
        frontAdminDashboard,
        salt: "$2a$10$YhD5a/EFtXD3epgqVxdR3O"
    };
    //console.log("app " + JSON.stringify(result));

    return result;
}