import React, { useCallback, useState, useRef, useContext, useEffect } from 'react';
import { ErrorMessage, Field, Form, Formik } from "formik";
import { FormGroup } from "react-bootstrap";
import * as yup from "yup";
import "./loginScreem.css";
import log from "../../images/logo_iproveedor.png";
import { useDispatch } from 'react-redux';
import { startLogin } from '../../redux/actions/auth';
import { useIntl, FormattedMessage } from 'react-intl';
import { Context } from '../context/Context';
import LoadingOverlay from 'react-loading-overlay';
import { TexTranslate } from '../../translations/FlattenMessages';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useHistory } from 'react-router';
import { Timer } from '../ui/Timer.jsx';
import { useLoginCore, processResponseLoginCore, useCloseSessionCore } from '../../hooks/useLoginCore';
import  { useGuardian } from '../../hooks/useGuardian'
import { ShowstrictMessage } from "../../componets/showMessage/ShowMessage";

import { LiveChatWidget } from '@livechat/widget-react';

export const LoginScreem = () => {
  const history = useHistory();
  const { user, setUser, app } = useContext(Context);

  const paramChange = () => {

    if (history.location.search.indexOf("&changePass=true") >= 0) {
      let params = history.location.search.replace("&changePass=true", "");
      history.push(`/${app.baseName}/changePass.html${params}`, {});
    }else if (history.location.search.indexOf("recover=true") >= 0) {
      let params = history.location.search.replace("&recover=true", "")
                    .replace("?recover=true", "")
                      .replace("recover=true", "");
      history.push(`/${app.baseName}/recover.html${params}`, {});
    }
  }

  paramChange();

  const dispatch = useDispatch();
  const [valueSelect] = useState(user.language);
  const [valueCap, setValueCap] = useState(user.language);
  const [showLoader, setShowLoader] = useState(false);
  const { dataResGuard, getGuardian } = useGuardian();

  const formSchema = yup.object({
    username: yup.string().required(<FormattedMessage id={"login.user required"} defaultMessage={"login.user required"} />),
    password: yup.string().required(<FormattedMessage id={"login.password required"} defaultMessage={"login.password required"} />),
  }); 

  useEffect(() => {
    getGuardian("login");
    actualizarGuardian();
  }, []);

  const refreshMessage = () => {
    ShowstrictMessage(
      {
        title: TexTranslate(intl, "general.Warning"),
        message: TexTranslate(intl, "login.Due to inactivity and security, the page will automatically refresh"),
        typeMessage: "warning",
        confirmButtonText: TexTranslate(intl, "general.Accept"),
        callbackConfirm: (() => {
          window.location.reload();
        })
      }
    );
  };

  const actualizarGuardian = () => {
    setTimeout(() => {
      refreshMessage();
    }, 600000); // 10 minutos para pruebas
  }

  const intl = useIntl();

  const [showTimer, setShowTimer] = useState(false);
  const [loadingTime, setLoadingTime] = useState(60);

  const { loginCore } = useLoginCore();
  const { closeSessionCore } = useCloseSessionCore();

  const handleChangeSelect = (e) => {

    setUser({
      language: e.target.value
    });

    sessionStorage.setItem( 'idioma' , e.target.value);

    switch (e.target.value) {
      case "es":
        setValueCap("es");
        break;

      case "en":
        setValueCap("en");
        break;

      case "pt_BR":
        setValueCap("pt-BR");
        break;

      default:
        break;
    }

  }

  const getRecaptchaToken = async () => {
    try {
      const token = await window.grecaptcha.execute();
      return token;
    } catch (error) {
      console.error('Error al obtener el token de reCAPTCHA:', error);
      return '';
    }
  };

  const handleRecoverPass = () => {
    history.push(`/${app.baseName}/recover.html`, {});
  }

  if (sessionStorage.getItem("userRedirect")) {
    if(sessionStorage.getItem("token.type") === "admin" && app.frontAdminDashboard !== undefined){
      window.location.href = `${app.frontAdminDashboard}/index.html`;
      return(<></>);
    }
    window.location.href = `${app.frontDashboard}/index.html`;
    return(<></>);
  } else if (sessionStorage.getItem("token.type")){
    if (sessionStorage.getItem("token.type") === "admin") {
      window.location.href = `${app.apiCore}/AdminEx.jsp?lang_user=${sessionStorage.getItem("idioma") || 'es'}`;
      return(<></>);
    } else if (sessionStorage.getItem("token.type") === "funder") {
      window.location.href =`${app.apiCore}/Funder.jsp?lang_user=${sessionStorage.getItem("idioma") || 'es'}`
      return(<></>);
    }
  }
  function handleNewEvent(event) {
    console.log("LiveChatWidget.onNewEvent", event);
  }
  return (
    <Context.Provider value={{ showTimer, setShowTimer }} >
      <LoadingOverlay active={showLoader} spinner
        text={TexTranslate(intl, "general.loading...", "general.loading...")}>

        <div className="section-login">
          <div className="container">
            <div className="form">
              <h2><img src={log} width="250" alt="logo iProveedor" /></h2>

              {showTimer ? <div className="divTimer"> <Timer loadingTime={loadingTime} /> </div> :
                < >
                  <Formik
                    initialValues={{ username: "", password: "", captcha: "" }}
                    validationSchema={formSchema}
                    onSubmit={async (data, { setSubmitting }) => {
                      setShowLoader(true);
                      setSubmitting(true);
                      const newRecaptchaToken = await getRecaptchaToken();
                      dispatch(startLogin(data.username, data.password, newRecaptchaToken, undefined, setShowLoader, TexTranslate, intl, app, setShowTimer, setLoadingTime, history, loginCore, processResponseLoginCore, closeSessionCore));
                      setSubmitting(false);
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (

                      <Form>
                        <div className="inputBox">
                          <select
                            value={valueSelect}
                            id="selectLanguage"
                            onChange={(e) => {
                              handleChangeSelect(e);
                            }}>
                            <option value="es" defaultValue>Español</option>
                            <option value="en">Ingles</option>
                            <option value="pt_BR">Portugues</option>
                          </select>
                        </div>

                        <FormGroup className="inputBox ">
                          <Field className="input" id="username" name="username" type="text" placeholder={TexTranslate(intl, "login.user", "login.user")} />
                          <ErrorMessage
                            name={"username"}
                            component="div"
                            className="field-error text-danger"
                          />
                        </FormGroup>
                        <FormGroup className="inputBox">
                          <Field className="input" id="password" name="password" type="password" placeholder={TexTranslate(intl, "login.password", "login.password")} />
                          <ErrorMessage
                            name="password"
                            component="div"
                            className="field-error text-danger"
                          />
                        </FormGroup>
                        <FormGroup className="inputBox">
                          <button className=" submit-login input" type="submit" >
                            <FormattedMessage id={"login.sign in"} defaultMessage={"login.sign in"} />
                          </button>
                        </FormGroup>

                        <p className="forget">
                          <FormattedMessage id={"login.Forgot your password?"} defaultMessage={"login.Forgot your password?"} />
                          <button className='button-forget' onClick={() => handleRecoverPass()}>
                            <FormattedMessage id={"login.Click Here"} defaultMessage={"login.Click Here"} />
                          </button>
                        </p>
                        <p className="forget none">Cambió su Email? <button className='button-forget'>Clic Aquí</button> </p>


                      </Form>
                    )}
                  </Formik>
                </>
              }
            </div>
          </div>
        </div>
      </LoadingOverlay>

      <LiveChatWidget
      license="11898495"
      visibility="minimized"
      onNewEvent={handleNewEvent}
    />

    </Context.Provider>

  );
};
