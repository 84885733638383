
export const InitialValuesUser = () =>{

    sessionStorage.removeItem( 'userRedirect' );
    sessionStorage.removeItem( 'login' );
    sessionStorage.removeItem( 'email' );
    sessionStorage.removeItem( 'idioma' );
    sessionStorage.removeItem( 'token.type' );
    sessionStorage.removeItem( 'token' );
    sessionStorage.removeItem( 'userData' );
    sessionStorage.removeItem( 'isAuthenticated' );
    sessionStorage.removeItem( 'captcha' );
}