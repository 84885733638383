import axios from "axios";

export default async function Apicall({
    method = 'post',
    url,
    data,
    headers = {},
    noAuth,
    props = {}
}){
    try {
        const authHeader = sessionStorage.getItem("userRedirect") && noAuth !== true ? {'Authorization': 'Bearer '.concat(sessionStorage.getItem("userRedirect"))} : {};
        
        const response = await axios({
            method,
            url,
            data,
            headers : { ...headers, 
                ...authHeader 
            },
            ...props
        });

        return response;

    } catch (error) {
        Promise.reject(error);
    }
}

export const createCall = ({base, params}) => {
    let stringParams = "";
    for(const [key,value] of Object.entries(params)){
        if(stringParams !==""){
            stringParams+="&";
        }
        stringParams+= key + "=" + value;
    }
    
    return base + "?" + stringParams;
};