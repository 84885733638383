import React, { useState, useRef, useContext, useEffect} from 'react';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { FormattedMessage } from 'react-intl';
import { Context } from '../context/Context';

const RenderTime = ({ remainingTime }) => {
  const currentTime = useRef(remainingTime);
  const prevTime = useRef(null);
  const isNewTimeFirstTick = useRef(false);
  const [, setOneLastRerender] = useState(0);

  const{setShowTimer} = useContext(Context);

  if (currentTime.current !== remainingTime) {
    isNewTimeFirstTick.current = true;
    prevTime.current = currentTime.current;
    currentTime.current = remainingTime;
  } else {
    isNewTimeFirstTick.current = false;
  }



  // force one last re-render when the time is over to tirgger the last animation
  //if (remainingTime === 0) {
     // setShowTimer(false);
    /*setTimeout(() => {
      setOneLastRerender(val => val + 1);
    }, 20);*/
  //}

  useEffect(() => {
    if (remainingTime === 0) {        
        setShowTimer(false);
    }
  }, [remainingTime])

  const isTimeUp = isNewTimeFirstTick.current;

  return (
    <div className="time-wrapper">
      <div key={remainingTime} className={`time fs-4 ${isTimeUp ? "up" : ""}`}>
        {remainingTime}
      </div>
      {prevTime.current !== null && (
        <div
          key={prevTime.current}
          className={`time ${!isTimeUp ? "down" : ""}`}
        >
        </div>
      )}
    </div>
  );
};

export const Timer = ({loadingTime}) => {

    return (
      <div className="Timer">
        <h5 className="text-center">
            <FormattedMessage id={`login.Your user have been blocked temporarily, please wait`} defaultMessage={"login.Your user have been blocked temporarily, please wait"} />
        </h5>
        <br />
        <div className="timer-wrapper d-flex justify-content-center">
          <CountdownCircleTimer
            isPlaying
            duration={loadingTime}
            colors={[["#A30000", 0.33], ["#F7B801", 0.33], ["#004777"]]}
          >
            {RenderTime}
          </CountdownCircleTimer>
        </div>
        <br />
      </div>
    );
}
    
