import { useContext } from "react";
import { Context } from "../componets/context/Context";
import { Base64 } from "js-base64";


export const useLoginCore = () => {

    const { app } = useContext(Context);

    const loginCore = async ({param,setData,value}) => {
        let response;
        let url;
        let buf;
        let bodyToken = {};
        let idiomaValue;
        let tokenValue;
        try {
            param = param ? param : {};
            idiomaValue = value && value.idioma ? value.idioma : undefined;
            tokenValue = value && value.token ? value.token : undefined;
            param.tokenIP4 = true;
            param.idiomaIP4 = idiomaValue || sessionStorage.getItem("idioma") || 'es';
            const token = tokenValue || sessionStorage.getItem("token") || sessionStorage.getItem("userRedirect");
            bodyToken.token = token;

            url = createCall({
                base: app.apiCore + "/control/LoginControl.jsp" , 
                params : param
            });

            buf = Base64.encode(JSON.stringify(bodyToken));

            const respFetch = await fetch(url,{
                    method:'POST',
                    body: buf,
                    credentials : "include"
                }        
            );

            const result = await respFetch.json();

            if ( result && result.success === true) {
               response = result;
            } else if (result.errorMsg ) {
                response = {...result, success : false , msg : result.errorMsg};
            } else if (result.error_code ) {
                    response = {...result, success : false , msg : result.error_code};
            } else {
                response = result;
                response.msg = "login.core.error";
            }
            if(response && setData) {
                setData(response);
            }
            return response;
        } catch (error) {
            console.log(error);
            if(setData){
                setData({ success : false, "msg" : "error"});
            }
            return { success : false, "msg" : "error"};
        }
    };

    return {
        loginCore
    };

}

export const createCall = ({base, params}) => {
    let stringParams = "";
    for(const [key,value] of Object.entries(params)){
        if(stringParams !==""){
            stringParams+="&";
        }
        stringParams+= key + "=" + value;
    }
    
    return base + "?" + stringParams;
};

export const processResponseLoginCore = ({msg,msgParams,TexTranslate,intl}) => {
    let result;
    let param;
    const StrParams = (msg, params)=>{
        params.forEach((element, index) => {
            msg = String(msg).replace("(("+index+"))", element);
        });
        return msg;  
    }

    switch (msg) {
        case "error.user.notActive":
            result = TexTranslate(intl, "login.error_user_notActive", "login.error");
            break;
        case "error.user.faliurePassword":
            result =TexTranslate(intl, "login.error_faliurePassword", "login.error");
            break;
        case "Invalid.Code.Auth":
            result =TexTranslate(intl, "login.access_code.incorrect_msg", "login.error");
            break;
        case "You do not have authorization to connect from IP {0}. Please, consult the administrator or contact technical support":
        case "error.user.faliureIP":
            if(msgParams){
                param = [msgParams.IP]
                result = StrParams(TexTranslate(intl, "login.error_faliureIP", "login.error"),param);
            } else {
                result = StrParams(TexTranslate(intl, "login.error_faliureIP", "login.error"),["0.0.0.0"]);
            }
            
            break;
        case "invalid data":
            result = TexTranslate(intl, "login.error_invalidData", "login.error");
            break;
        case "You don't have permissions to access the selected client !!":
            result = TexTranslate(intl, "login.error_clientPermissions", "login.error");
            break;
        case "invalidToken":
            result = TexTranslate(intl, "login.Due to inactivity and security, the page will automatically refresh", "login.error");
            break;
        default:
            break;
    }
    return result;

}

export const useCloseSessionCore = () => {

    const { app } = useContext(Context);

    const closeSessionCore = async ({param,setData}) => {
        let response;
        let url;
        try {
            param = param ? param : {};
            param.borrar_sesion = true;

            url = createCall({
                base: app.apiCore + "/control/MainControl.jsp" , 
                params : param
            });

            const respFetch = await fetch(url,{
                    method:'POST',
                    credentials : "include"
                }        
            );

            const result = await respFetch.json();

            if ( result !== undefined ) {
               response = result;
            } else {
                response = { success : false, "msg" : "error.closeSesion"};
            }
            if(response && setData) {
                setData(response);
            }
            return response;
        } catch (error) {
            console.log(error);
            if(setData){
                setData({ success : false, "msg" : "catch.closeSesion"});
            }
            return { success : false, "msg" : "catch.closeSesion"};
        }
    };

    return {
        closeSessionCore
    };

}