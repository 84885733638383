import { TexTranslate } from '../../translations/FlattenMessages';

export const ShowMessage = (
    {
        title, 
        message, 
        typeMessage = 'success', 
        showCancelButton = false,
        intl,
        confirmButtonText =  TexTranslate(intl, "general.Accept", "general.Accept"),
        cancelButtonText = 'Cancelar',
        callbackConfirm,
        html
    }) =>{
    
    const Swal = require('sweetalert2');
    
    Swal.fire({
        title,
        text: message,
        icon: typeMessage,
        showCancelButton,
        confirmButtonText,
        cancelButtonText,
        html
    })
    .then((result) =>{
        if( result.isConfirmed ){
            if( callbackConfirm ){
                callbackConfirm();
            }
        }
    });

    return Swal;
}

export const ShowstrictMessage = (
    {
        title, 
        message, 
        typeMessage = 'success', 
        showCancelButton = false,
        intl,
        confirmButtonText =  TexTranslate(intl, "general.Accept", "general.Accept"),
        cancelButtonText = 'Cancelar',
        callbackConfirm,
        html
    }) =>{
    
    const Swal = require('sweetalert2');
    
    Swal.fire({
        title,
        text: message,
        icon: typeMessage,
        showCancelButton,
        confirmButtonText,
        cancelButtonText,
        html,
        backdrop:'static',
        allowOutsideClick: false
    })
    .then((result) =>{
        if( result.isConfirmed ){
            if( callbackConfirm ){
                callbackConfirm();
            }
        }
    });

    return Swal;
}