import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './sass/index.css';

import { LoginApp } from './LoginApp';
import { InitialValuesUser } from './service/loginServices';

const validUrl = () => {
  let url = window.location.href;
  let arrayParams = url.split("?");
  let stArrayParams;

  if (arrayParams && arrayParams[1]) {
    arrayParams = arrayParams[1].split("&");
    for (let index = 0; index < arrayParams.length; index++) {
      stArrayParams = arrayParams[index].split("=");
      if (stArrayParams[0] === "end") {
        const lang = sessionStorage.getItem( 'idioma' ) || sessionStorage.getItem( 'idiomaOut' );
        InitialValuesUser();
        if(lang && lang !== 'null'){
          sessionStorage.setItem( 'idioma' , lang);
        }
        sessionStorage.removeItem( 'idiomaOut' );
      }
    }
  }

}

validUrl();

ReactDOM.render(

  <LoginApp />,

  document.getElementById('root')
);
