import { fetchLoggin, fetchIncorrectLoggin, fetchPasswordValidity, fetchGetBody } from "../../helpers/fetch";
import { types } from "../../types/types";
import { calcMD5 } from "../../helpers/md5";
import { ShowMessage } from "../../componets/showMessage/ShowMessage";
import bcryptjs from "bcryptjs";

export const startLogin = (username, password, captcha, code, setShowLoader, TexTranslate, intl, app, setShowTimer, setLoadingTime, history,loginCore, processResponseLoginCore, closeSessionCore ) => {
    
    return async (dispatch) => {

        let selectLanguage = document.getElementById("selectLanguage");
        
        const passwordBcrypt = await bcryptjs.hash (password, app.salt);
        let passEncr = code != undefined ? password: calcMD5(password);        
        let jsonDataAuth;
        let loginResponse;
        let msgProcessLoginCore;

        let data = {
            username,
            "password": passwordBcrypt,
            "passwordBcrypt": true,
            "captcha": captcha
        };
        if(code !== undefined){
            data["code"] = code;
        }
        data["language"] = selectLanguage.value || 'es'
        let resp = await fetchLoggin(data, 'POST', app);
        
        if(!resp.ok && resp.status === 400){
            data = {
                username,
                "password": passEncr,
                "passwordBcrypt": false,
                "passwordHashBcrypt": passwordBcrypt,
                "captcha": captcha
            };
            resp = await fetchLoggin(data, 'POST', app);
            if(resp.ok){
                data.password = passwordBcrypt;
                data.passwordBcrypt = true;
            }
        }
               
        const body = await resp.json();

        if(!resp.ok && resp.status === 401 ) {
            if (body.error_description === "Request.Code.Auth" 
                || body.error_description === "Invalid.Code.Auth") {
                setShowLoader(false);
                data.password = passwordBcrypt;
                data.passwordBcrypt = true;
                dispatch(loginCode({ loginData: { ...data } }));
                history.push(`/${app.baseName}/codeLogin.html`, {});
                return;
            }  else {
                setShowLoader(false);
                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        intl
                    }
                )
                dispatch(checkingFinish());
            }
        }else
        if (body.access_token ) {

            const respPswVald = await fetchPasswordValidity(app, body.access_token);

            if (respPswVald && respPswVald.tiempoBloqueo < 60){

                setShowLoader(false);
                setLoadingTime(respPswVald.tiempoBloqueo);
                setShowTimer(true);

            } else {

                if(respPswVald?.success && respPswVald.expired){
                    setShowLoader(false);
                    dispatch(login({ ...respPswVald, "token" : body.access_token}));
                    history.push(`/${app.baseName}/changePassMandatory.html`, {});
                    return;
                }

                const respBody = await fetchGetBody(app, body.access_token);
                const tokenJson = respBody.userData;
            
                if (tokenJson) {
                    jsonDataAuth = (tokenJson.type_user).toLowerCase();

                    if (jsonDataAuth === 'supplier') {
                        let value = {
                            token : body.access_token,
                            idioma : selectLanguage.value
                        }
                        loginResponse = await loginCore({"value" : value});

                        if (loginResponse?.success) {
                            sessionStorage.setItem('userRedirect', body.access_token);
                            sessionStorage.setItem('login', tokenJson.user_name);
                            sessionStorage.setItem('email', tokenJson.email);
                            sessionStorage.setItem('idioma', selectLanguage.value);
                            sessionStorage.setItem('token.type', jsonDataAuth);
                            dispatch(login(body));
                        } else {
                            msgProcessLoginCore = processResponseLoginCore({
                                "msg" : loginResponse.msg,
                                "msgParams" : loginResponse,
                                "TexTranslate" : TexTranslate,
                                "intl" : intl
                            });
                            setShowLoader(false);

                            if(msgProcessLoginCore) {
                                ShowMessage(
                                    {
                                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                        message: msgProcessLoginCore,
                                        typeMessage: "warning",
                                        showCancelButton: false,
                                        intl
                                    }
                                );
                            } else {
                                ShowMessage(
                                    {
                                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                                        typeMessage: "warning",
                                        showCancelButton: false,
                                        intl
                                    }
                                );
                            }
                            dispatch(checkingFinish());
                        }
                        
                    } else if (jsonDataAuth === 'admin' || jsonDataAuth === 'funder') {
                        if(jsonDataAuth === 'admin'){
                            let value = {
                                token : body.access_token,
                                idioma : selectLanguage.value || 'es'
                            }
                            loginResponse = await loginCore({"value" : value});
    
                            if (loginResponse?.success) {
                                sessionStorage.setItem('userRedirect', body.access_token);
                                sessionStorage.setItem('idioma', selectLanguage.value);
                                sessionStorage.setItem('token.type', jsonDataAuth);
                                dispatch(login(body));
                                return;
                            } else {
                                msgProcessLoginCore = processResponseLoginCore({
                                    "msg" : loginResponse.msg,
                                    "msgParams" : loginResponse,
                                    "TexTranslate" : TexTranslate,
                                    "intl" : intl
                                });
                                setShowLoader(false);
    
                                if(msgProcessLoginCore) {
                                    ShowMessage(
                                        {
                                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                            message: msgProcessLoginCore,
                                            typeMessage: "warning",
                                            showCancelButton: false,
                                            intl
                                        }
                                    );
                                } else {
                                    ShowMessage(
                                        {
                                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                            message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                                            typeMessage: "warning",
                                            showCancelButton: false,
                                            intl
                                        }
                                    );
                                }
                                dispatch(checkingFinish());
                                return;
                            }
                        }

                        let value = {
                            token : body.access_token,
                            idioma : selectLanguage.value
                        }
                        loginResponse = await loginCore({"value" : value});

                        if (loginResponse?.success) {
                            sessionStorage.setItem('token', body.access_token);
                            sessionStorage.setItem('token.type', jsonDataAuth);
                            sessionStorage.setItem('idioma', selectLanguage.value);
                            if (jsonDataAuth === "funder") {
                                window.location.href =`${app.apiCore}/Funder.jsp?lang_user=${selectLanguage.value || 'es'}`
                            }
                        } else {
                            msgProcessLoginCore = processResponseLoginCore({
                                "msg" : loginResponse.msg,
                                "msgParams" : loginResponse,
                                "TexTranslate" : TexTranslate,
                                "intl" : intl
                            });
                            setShowLoader(false);
                            if(msgProcessLoginCore) {
                                ShowMessage(
                                    {
                                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                        message: msgProcessLoginCore,
                                        typeMessage: "warning",
                                        showCancelButton: false,
                                        intl
                                    }
                                );
                            } else {
                                ShowMessage(
                                    {
                                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                                        typeMessage: "warning",
                                        showCancelButton: false,
                                        intl
                                    }
                                );
                            }
                            dispatch(checkingFinish());
                        }
                    } else {
                        setShowLoader(false);
                        console.log("typeUser.error");
                    }
                } else {
                    setShowLoader(false);
                }
            }

        } else {            
            const respLogin = await fetchIncorrectLoggin(data, app);
            
            if (respLogin?.success && respLogin.intentosLogueo < 5){
                setShowLoader(false);
                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        intl
                    }
                )
                dispatch(checkingFinish());
            } else if (respLogin && !respLogin.success && respLogin.intentosLogueo && respLogin.intentosLogueo >= 5){
                setShowLoader(false);
                //setLoadingTime(Math.floor((respLoginAttempts.tiempoBloqueo/1000) % 60));
                setLoadingTime(60);
                setShowTimer(true);
            }else {                
                setShowLoader(false);
                ShowMessage(
                    {
                        title: TexTranslate(intl, "general.Warning", "general.Warning"),
                        message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                        typeMessage: "warning",
                        showCancelButton: false,
                        intl
                    }
                )
                dispatch(checkingFinish());
            }           
        }

    }

}

export const continueLoginCode = (loginData, setShowLoader, TexTranslate, intl, app, setShowTimer, setLoadingTime, history,loginCore, processResponseLoginCore ) => {
    return async (dispatch) => {
        if (loginData.error_description === 'Invalid.Code.Auth') {
            setShowLoader(false);
           ShowMessage(
                {
                    title: TexTranslate(intl, "general.Warning", "general.Warning"),
                    message: TexTranslate(intl, "login.access_code.incorrect_msg", "login.access_code.incorrect_msg"),
                    typeMessage: "warning",
                    showCancelButton: false,
                    intl
                }
            );
            dispatch(checkingFinish());
        } else {
            let msgProcessLoginCore;
            const value = {
                token : loginData.access_token,
                idioma : loginData.language,
            }
            const loginResponse = await loginCore({"value" : value});
    
            if(loginResponse?.success){
    
                const respBody = await fetchGetBody(app, loginData.access_token);
                const tokenJson = respBody.userData;            
    
                if(tokenJson) {
                    if (tokenJson.type_user.toLowerCase() === "admin") {
                        sessionStorage.setItem('userRedirect', loginData.access_token);
                        sessionStorage.setItem('idioma', loginData.language);
                        sessionStorage.setItem('token.type', tokenJson.type_user.toLowerCase());
                        const authBody = {
                            "access_token" : loginData.access_token,
                            "loginData" : undefined
                        }
                        dispatch(login(authBody));
                        return;
                    }
    
                    sessionStorage.setItem('token', loginData.access_token);
                    sessionStorage.setItem('token.type', tokenJson.type);
                    if (tokenJson.type_user.toLowerCase() === "funder") {
                        window.location.href =`${app.apiCore}/Funder.jsp?lang_user=${loginData.language || 'es'}`
                    }
                } else {
                    setShowLoader(false);
                }
            } else {
                msgProcessLoginCore = processResponseLoginCore({
                    "msg" : loginResponse.msg,
                    "msgParams" : loginResponse,
                    "TexTranslate" : TexTranslate,
                    "intl" : intl
                });
                setShowLoader(false);
                if(msgProcessLoginCore) {
                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: msgProcessLoginCore,
                            typeMessage: "warning",
                            showCancelButton: false,
                            intl
                        }
                    );
                } else {
                    ShowMessage(
                        {
                            title: TexTranslate(intl, "general.Warning", "general.Warning"),
                            message: TexTranslate(intl, "login.incorrect username or password", "login.incorrect username or password"),
                            typeMessage: "warning",
                            showCancelButton: false,
                            intl
                        }
                    );
                }
                dispatch(checkingFinish());
            }
        }
    }
}

const checkingFinish = () => ({
    type: types.authCheckingFinish
})

const login = (user) => ({
    type: types.authLogin,
    payload: user
})

const loginCode = (data) => ({
    type: types.authLoginCode,
    payload: data
})
