import React, { useState } from 'react';
import './tooltip.css';

const Tooltip = ({ text, image }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <div className="tooltip-container">
      <img
        src={image}
        alt="question"
        className="tooltip-icon"
        onMouseEnter={toggleTooltip}
        onMouseLeave={toggleTooltip}
        onClick={toggleTooltip}
      />
      {showTooltip && <div className="tooltip-text">{text}</div>}
    </div>
  );
};

export default Tooltip;
