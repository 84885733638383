import React, { useCallback, useEffect, useState } from 'react'

import { useSelector } from "react-redux";

import {
    BrowserRouter as Router,
    Switch,
    Redirect
} from "react-router-dom";
import { LoginScreem } from '../componets/auth/LoginScreem';
import { DashboardScreem } from '../componets/user/DashboardScreem';
import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';
import { Context } from '../componets/context/Context';
import { FlattenMessages } from '../translations/FlattenMessages';
import { IntlProvider } from 'react-intl';
import Locales, { getLocalesProvider } from '../translations/Locales';
import { RecoverPassword } from '../componets/auth/RecoverPassword';
import { ChangePassword } from '../componets/auth/ChangePassword';
import { ChangePasswordMandatory } from '../componets/changePassword/ChangePasswordMandatory';
import { CodeLogin } from '../componets/codeLogin/CodeLogin';
import { getAppURL } from "./getAppUrl";
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

export const AppRouter = () => {

    const { checking, access_token, token, expired, loginData } = useSelector(state => state.auth);

    const [user, setUser] = useState({
        language: sessionStorage.getItem('idioma') || 'es'
    });

    const [app, setApp] = useState(getAppURL);

    const onVerify = useCallback((captcha) => {
        sessionStorage.setItem('captcha', captcha);
    }, []);

    useEffect(() => {
    }, [checking])

    const locale = Locales(getLocalesProvider(user.language));
    const langChange = user.language

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey="6Ld8DqsUAAAAAOGuDjOCS_KSqjYZMzCiGb7U6VQH"
            language={langChange}
            container={{
                element: "[required_id_or_htmlelement]",
                parameters: {
                    badge: 'bottomleft',
                }
            }}
        >
            <GoogleReCaptcha
                onVerify={onVerify}
            />
            <Context.Provider value={{ user, setUser, app, setApp }} >
                <IntlProvider locale={locale.language} messages={FlattenMessages(locale.messages)} defaultLocale="es" key={'login'}>
                    <Router >
                        <div>
                            <Switch>
                                <PublicRoute exact path={`/${app.baseName}/index.html`} component={LoginScreem} isAuthenticated={!!access_token} />
                                <PublicRoute exact path={`/${app.baseName}/recover.html`} component={RecoverPassword} isAuthenticated={!!access_token} />
                                <PublicRoute exact path={`/${app.baseName}/changePass.html`} component={ChangePassword} isAuthenticated={!!access_token} />
                                <PublicRoute exact path={`/${app.baseName}/codeLogin.html`} component={CodeLogin} isAuthenticated={!loginData} />
                                <PrivateRoute exact path={`/${app.baseName}/changePassMandatory.html`} component={ChangePasswordMandatory} redirect={`/${app.baseName}/index.html`} isAuthenticated={!!expired && !!token} />
                                <PrivateRoute exact path={`/${app.baseNameDashboard}/index.html`} component={DashboardScreem} redirect={`/${app.baseName}/index.html`} isAuthenticated={!!access_token} />
                                <Redirect to={`/${app.baseNameDashboard}/index.html`} />
                            </Switch>
                        </div>
                    </Router>
                </IntlProvider>
            </Context.Provider>
        </GoogleReCaptchaProvider>
    )
}
