import { defineMessages } from "react-intl";

export const FlattenMessages = ((nestedMessages, prefix = '') => {
    if (nestedMessages === null) {
        return {}
    }
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const value = nestedMessages[key]
        const prefixedKey = prefix ? `${prefix}.${key}` : key

        if (typeof value === 'string') {
            Object.assign(messages, { [prefixedKey]: value })
        } else {
            Object.assign(messages, FlattenMessages(value, prefixedKey))
        }

        return messages
    }, {})
});

export const TexTranslate = (intl, text, param) =>{

    const messages = defineMessages({
        alerts:{
            id:text,
            defaultMessage:'No se encontró la traducción!'// {param}
        }
    });

    return intl.formatMessage(messages.alerts, {});
}
