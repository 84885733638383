
export const types = {

    authChecking : '[auth] Checking login state',
    authCheckingFinish : '[auth] Finish checking login state',
    authStartLogin : '[auth] Start login',
    authLogin : '[auth] Login',
    authLoginCode : '[auth] Login with Code',
    authLogout : '[auth] Logout'

}