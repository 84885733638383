import { useState, useEffect } from "react";
import Messages_es from "../translations/Messages_es.json";
import Messages_en from "../translations/Messages_en.json";
import Messages_pt from "../translations/Messages_pt.json";

export default function Locales (dataLanguage) { 

    const [language, setLanguage] = useState(dataLanguage);
    const [messages, setMessages] = useState(Messages_es);

    useEffect(()=>{

      switch (dataLanguage) {
        case 'es':
          setMessages(Messages_es);
          break;
        case 'en':
          setMessages(Messages_en);
          break;
        case 'pt':
          setMessages(Messages_pt);
          break;
        case 'pt_BR':
          setMessages(Messages_pt);
          break;
        default:
          setMessages(Messages_es);
          break;
      }
    },[dataLanguage]);

    return {messages, language, setLanguage};

}


export function getLocalesProvider(locale){

  switch (locale) {
    case "pt_BR":
      return "pt";  
    default:
      return locale;
  }

}